import { HttpHeader } from '@solidjs/start';
import { Show, onMount, createSignal } from 'solid-js';
import { PersistentClient } from '~/shared/PersistentClient';
import { CoreBand } from '~/shared/CoreBand';
import { base64Encode } from '~/utils/base64';
import { searchParam } from '~/components/designer2/BandComponent';
import { LogComponent } from '~/components/designer2/LogComponent';
import { BandProps, DynamicBand } from '~/components/designer2/DynamicBand';
import
	{
	defaultPartitionProps,
	PartitionProps,
	minPartitionProps,
	maxPartitionProps,
	stepPartitionProps,
	} from '~/components/designer2/DynamicPartition';
import { PartitionComponent } from '~/components/designer2/PartitionComponent';
import { OffscreenPartition } from '~/components/designer2/OffscreenPartition';
import { LogLevel } from '~/components/designer2/DynamicLog';

export default function Home()
	{
	const isIFrame = searchParam('iframe', 'i');
	const [sigGetCanvas1, sigSetCanvas1] = createSignal('/samples/test_card.png');
	const [sigGetCanvas2, sigSetCanvas2] = createSignal('/samples/test_card.png');
	const [sigGetCanvas3, sigSetCanvas3] = createSignal('/samples/test_card.png');
	const [sigGetCanvas4, sigSetCanvas4] = createSignal('/samples/test_card.png');
	const [sigGetCanvas5, sigSetCanvas5] = createSignal('/samples/test_card.png');
	const [sigGetCanvas6, sigSetCanvas6] = createSignal('/samples/test_card.png');
	const [sigGetCanvas7, sigSetCanvas7] = createSignal('/samples/test_card.png');
	const [sigGetCanvasRunning, sigSetCanvasRunning] = createSignal(0);
	onMount(() =>
		{
		if (isIFrame)
			{ // remove the menu toolbar at the top
			for (const header of document.getElementsByTagName("header"))
				{
				header.style.display = 'none';
				}
			}
		});
	return (
    // *INDENT-OFF*
		<main class="text-center p-4">
			<HttpHeader name="Cross-Origin-Embedder-Policy" value="require-corp" />
			<HttpHeader name="Cross-Origin-Opener-Policy" value="same-origin" />
			<HttpHeader name="Cross-Origin-Resource-Policy" value="cross-origin" />
			<Show when={isIFrame === false}>
				<div id="space_for_floating_menubar" style="height:48px;" />
			</Show>
			<h2>Introduction</h2>
			<p>This page contains a series of tests to find out if there are any standard html5 functions
			that we need to avoid in this browser.</p>
			<p>If you find a problem, please use the pink "copy log to clipboard" button below, and then send me the log, together with a brief note of what went wrong.
			You can use the blue "eMail log" button, or the orange "Post to Slack" button.
			For slack, the ctrl-shift-v pastes your clipboard - it will be too large and slack offers a snippet - make sure you select "plain text" or you will crash slack with markdown errors</p>
			<h2>Test Images</h2>
			<p>Seven test images, please report if any blank</p>
			<div class="w-[96vw]">
				<div class="flex">
					<div class="shrink"><img id="img1" src="/samples/kodim03.webp" style="width:100%;height:auto;"/></div>
					<div class="shrink"><img id="img2" src="/samples/kodim05.webp" style="width:100%;height:auto;"/></div>
					<div class="shrink"><img id="img3" src="/samples/kodim06.webp" style="width:100%;height:auto;"/></div>
					<div class="shrink"><img id="img4" src="/samples/kodim07.webp" style="width:100%;height:auto;"/></div>
					<div class="shrink"><img id="img5" src="/samples/kodim14.webp" style="width:100%;height:auto;"/></div>
					<div class="shrink"><img id="img6" src="/samples/kodim17.webp" style="width:100%;height:auto;"/></div>
					<div class="shrink"><img id="img7" src="/samples/kodim20.webp" style="width:100%;height:auto;"/></div>
				</div>
			</div>
			<h2>Canvas Test</h2>
			<button
				class="bg-wearshareblue rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
				onClick={() => 
					{
					const perisitentClient = PersistentClient.instance;
					sigSetCanvasRunning(7);
					perisitentClient.convertSrcToDataurl("/samples/kodim03.webp", 1024, 1024, 'red').then( (dataurl) => 
						{
						sigSetCanvas1(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSrcToDataurl("/samples/kodim05.webp", 1024, 1024, 'orange').then( (dataurl) => 
						{
						sigSetCanvas2(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSrcToDataurl("/samples/kodim06.webp", 1024, 1024, 'yellow').then( (dataurl) => 
						{
						sigSetCanvas3(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSrcToDataurl("/samples/kodim07.png", 1024, 1024, 'green').then( (dataurl) => 
						{
						sigSetCanvas4(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSrcToDataurl("/samples/kodim14.png", 1024, 1024, 'blue').then( (dataurl) => 
						{
						sigSetCanvas5(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSrcToDataurl("/samples/kodim17.png", 1024, 1024, 'indigo').then( (dataurl) => 
						{
						sigSetCanvas6(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSrcToDataurl("/samples/kodim20.png", 1024, 1024, 'violet').then( (dataurl) => 
						{
						sigSetCanvas7(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					}}
				>
				{sigGetCanvasRunning()>0 ? (
						<span class="flex items-center justify-center space-x-2">
							<div class="animate-spin h-5 w-5 border-2 border-black border-t-transparent rounded-full" />
							<span>Running Test ...</span>
						</span>
						) : (
						'Run Canvas Test'
						)}
			</button>
			<button
				class="bg-wearshareblue rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
				onClick={() => 
					{
					const perisitentClient = PersistentClient.instance;
					sigSetCanvasRunning(7);
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas1(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas2(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas3(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas4(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas5(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas6(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas7(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					}}
				>
				{sigGetCanvasRunning()>0 ? (
						<span class="flex items-center justify-center space-x-2">
							<div class="animate-spin h-5 w-5 border-2 border-black border-t-transparent rounded-full" />
							<span>Running Test ...</span>
						</span>
						) : (
						'Run SVG Test'
						)}
			</button>
			<button
				class="bg-wearshareblue rounded-lg border-black border-[3px] shadow-lg m-1 p-2"
				onClick={() => 
					{
					const perisitentClient = PersistentClient.instance;
					sigSetCanvasRunning(2);
					{
					const partition = new PartitionProps();
					partition.setDefaults();
					partition.bgcolor = '#FF0000'; // red
					const partitionRotation = 0;
					const bleedWidth = 0;
					const partitionDim = 1024;
					perisitentClient.convertSrcToDataurl("/samples/kodim03.png", 1024, 1024, '#FF0000', 'image/png').then( (dataurl) => 
						{
						partition.imgsrc = dataurl;
						const svgtext = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>'+
								OffscreenPartition(
									partition,
									partitionRotation,
									bleedWidth,
									0,
									partitionDim
									);
						PersistentClient.log(LogLevel.debug, 'SVG Partition Test: svg '+partition.index+' '+svgtext);
						perisitentClient.convertSvg2PngDataurl(svgtext).then( (dataurl) => 
							{
							sigSetCanvas1(dataurl);
							sigSetCanvasRunning(sigGetCanvasRunning() - 1);
							});
						});
					}
					{
					const partition = new PartitionProps();
					partition.setDefaults();
					partition.bgcolor = '#FFA500'; // orange
					const partitionRotation = 0;
					const bleedWidth = 0;
					const partitionDim = 1024;
					perisitentClient.convertSrcToDataurl("/samples/kodim05.webp", 1024, 1024, '#FFA500', 'image/webp').then( (dataurl) => 
						{
						partition.imgsrc = dataurl;
						const svgtext = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>'+
								OffscreenPartition(
									partition,
									partitionRotation,
									bleedWidth,
									0,
									partitionDim
									);
						PersistentClient.log(LogLevel.debug, 'SVG Partition Test: svg '+partition.index+' '+svgtext);
						perisitentClient.convertSvg2PngDataurl(svgtext).then( (dataurl) => 
							{
							sigSetCanvas2(dataurl);
							sigSetCanvasRunning(sigGetCanvasRunning() - 1);
							});
						});
					}
					/*
					perisitentClient.convertSvg2PngDataurl('<svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas2(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas3(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas4(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas5(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas6(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					perisitentClient.convertSvg2PngDataurl('<svg viewBox="0 0 1024 1024" width="1024" height="1024" xmlns="http://www.w3.org/2000/svg"><rect x="64" y="64" width="512" height="512"/></svg>').then( (dataurl) => 
						{
						sigSetCanvas7(dataurl);
						sigSetCanvasRunning(sigGetCanvasRunning() - 1);
						});
					*/
					}}
				>
				{sigGetCanvasRunning()>0 ? (
						<span class="flex items-center justify-center space-x-2">
							<div class="animate-spin h-5 w-5 border-2 border-black border-t-transparent rounded-full" />
							<span>Running Test ...</span>
						</span>
						) : (
						'Run SVG Partition Test'
						)}
			</button>
			<div class="w-[96vw]">
				<div class="flex">
					<div class="shrink"><img id="canvas1" src={sigGetCanvas1()} style="background-color:#FF0000;width:100%;height:auto;"/></div>
					<div class="shrink"><img id="canvas2" src={sigGetCanvas2()} style="background-color:#FFA500;width:100%;height:auto;"/></div>
					<div class="shrink"><img id="canvas3" src={sigGetCanvas3()} style="background-color:#FFFF00;width:100%;height:auto;"/></div>
					<div class="shrink"><img id="canvas4" src={sigGetCanvas4()} style="background-color:#008000;width:100%;height:auto;"/></div>
					<div class="shrink"><img id="canvas5" src={sigGetCanvas5()} style="background-color:#0000FF;width:100%;height:auto;"/></div>
					<div class="shrink"><img id="canvas6" src={sigGetCanvas6()} style="background-color:#4B0082;width:100%;height:auto;"/></div>
					<div class="shrink"><img id="canvas7" src={sigGetCanvas7()} style="background-color:#EE82EE;width:100%;height:auto;"/></div>
				</div>
			</div>
			<h2>Partition Test</h2>
			<div class="w-[96vw]">
				<div class="flex">
					<PartitionComponent id="partition1" index="1" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
					<PartitionComponent id="partition2" index="2" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
					<PartitionComponent id="partition3" index="3" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
					<PartitionComponent id="partition4" index="4" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
					<PartitionComponent id="partition5" index="5" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
					<PartitionComponent id="partition6" index="6" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
					<PartitionComponent id="partition7" index="7" 
						classCSS='cursor-pointer shrink self-start' styleCSS='width:1024px;max-width:1024px;max-height:1024px;' styleSVG='width:100%;' dim='1024'/>
				</div>
			</div>
			<h2>Console Log</h2>
			<LogComponent level="5" nlines="0" maxlength="0" />
		</main>
    // *INDENT-ON*
	       );
	}
